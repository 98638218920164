<template>



<div>

 
  <b-modal
            ref="modalShowQR"
            centered
            hide-footer
            size="sm"
            title="Address de billetera"
            @hidden="closeModalQR"
          >

          <b-row>

            <b-col  align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 
            
                <vue-qr style="width: 200px !important;" :logoSrc="logoCripto" logoBackgroundColor="#fff" backgroundColor="#fff"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius" :correctLevel="correctLevel" :callback="getDataURL" :margin="margin"  :text="addressQR" :size="size"></vue-qr>



                </b-col>


                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 10px;">

                <b-input-group>

                <b-form-input
                      v-model="address"
                      readonly
                      />

                  <b-input-group-append>
                    <b-dropdown
                    variant="outline-primary"
                    right
                  >

                  <template  #button-content>
                        
                        Acciones <feather-icon
                          icon="ChevronDownIcon"
                          size="16"
                          style="color:white"
                          />

                        </template>
                      <b-dropdown-item    @click="onCopy"    >  <feather-icon
                          icon="CopyIcon"
                          size="15"
                          style="margin-right: 10px;"
                          /> Copiar address </b-dropdown-item>
                      <b-dropdown-item      @click="descargar">  
                        <feather-icon
                                    icon="DownloadCloudIcon"
                                    size="18"
                                    style="margin-right: 10px;"
                                  
                                    />Descargar imagen</b-dropdown-item>

                        <b-dropdown-item     @click="shared">  
                        <feather-icon
                                    icon="Share2Icon"
                                    size="18"
                                    style="margin-right: 10px;"
                                  
                                    />Compartir address</b-dropdown-item>

                                  
                    </b-dropdown>
                  </b-input-group-append>

                  </b-input-group>
                  

                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                  <b-alert
                        variant="primary"
                        show
                        style="text-align: center;margin-top: 10px;"
                      >
                        <div class="alert-body">
                          <span> Comparte tu address para recibir pagos internos y externos hacia tu billetera de {{this.currencyRespaldo}}</span>
                        </div>
                      </b-alert>


                  </b-col>


            </b-row>



          </b-modal>

    
 

  </div>
  
    
    
 </template>
    
    <script>
    import {
      BAlert, VBTooltip,BSkeleton,BButton,BDropdown,BDropdownItem,VBPopover, BRow, BCol, BCard,  BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
    } from 'bootstrap-vue'
    import VueQr from 'vue-qr'

    export default {
      components: {
        BButton,
        BInputGroup,
        BSkeleton,
        VueQr,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BRow,
        BCol,
        BAlert,
        BCard,BDropdown,BDropdownItem,
      },
      props: ['idWallet','address' ,'currency'],
        directives: {
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
      data() {

        let logoCripto="";


        let currencyRespaldo=this.currency;

        if(currencyRespaldo === "USDT"){
          logoCripto= "/img/usdt.svg"
        }else{
          currencyRespaldo= "BNB";
              logoCripto= "/img/bsc.svg"
        }


 
    
        return {
          size:800,
          margin:50,
          correctLevel:3,
          dataUrl:"",
          hideQR:true,
          isVisible:true,
          logoCripto:logoCripto,
          logoScale:0.3,
          logoMargin: 1,
          logoCornerRadius:100,
          addressQR: this.idWallet+";interbilletera",
          currencyRespaldo:currencyRespaldo,
          dialogMobile:false,
          color: "#242526",
      indentColor:"#b0b3b8",
      radius:"10px",
      overlay:"#000000e6",
          body: document.getElementsByTagName('body')[0],
        }
      },
      computed: {
    
      },
      watch: {
        dialogMobile(newValue) {


        if(newValue){
          this.body.classList.add("overflow-hidden");
        }else{
          this.$eventBus.$emit('reiniciarAddressWallet')
          this.body.classList.remove("overflow-hidden");
        }



        },
      },
      mounted() {

        this.$refs.modalShowQR.show();

    
      },
      methods: {

        closeModalQR(){
          this.$eventBus.$emit('reiniciarAddressWallet')
          this.$eventBus.$emit('reiniciarModalAddressUSDTDashboard')
          this.$eventBus.$emit('reiniciarRecargasConUSDT')

          
                 
        },
    
            getDataURL(dataUrl,id){
    
            
    
          this.dataUrl= dataUrl;
    
            this.hideQR=false;
    
        },
        
        descargar(){
    
          var link = document.createElement("a");
          link.download = "Mi address de billetera " +  this.currencyRespaldo;
          link.href =this.dataUrl;
          link.click();
           
           
    
        },
    
        shared(){
    
    
          fetch(this.dataUrl).then(function (response) {
            return response.blob()
          }).then(function (blob) {
            let file = new File([blob], "Address.jpg", {type: 'image/jpeg'});
            let filesArray = [file];
            if (navigator.canShare && navigator.canShare({files: filesArray})) {
              navigator.share({
                files: filesArray
              }).then(() => console.log('Successful share'))
                .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                }));
            }else{
    
                this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
    
            }
          });
    
    
        
    
        },
        onCopy() {
          
          navigator.clipboard.writeText(this.address);
          this.$toast.success('Address copiado', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onError() {
          this.$toast.error('Falló en copiar el address', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },  
    
    
      },
    }
    </script>
    
    <style lang="scss">
    

    
    
    </style>
    